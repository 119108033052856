

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
    background-clip: padding-box;
}

.leaflet-control-layers {
    background: none; 
}

.leaflet-control-layers-overlays input[type=checkbox] + span {
    color: white;
}

.leaflet-control-attribution {
  display: none;
}
